.container
  position: relative
  min-height: 300px

.tooltip
  position: absolute
  top: 0
  left: 0
  z-index: 10
  background-color: white
  border: 2px solid
  border-radius: 5px
  padding: 5px
  font-size: 13px
  opacity: 0
  pointer-events: none
