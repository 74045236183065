.container
	position: absolute
	top: 50%
	left: 50%
	display: flex
	justify-content: space-around
	align-items: center
	margin: 0 auto
	width: 100%
	transform: translate(-50%, -50%)

.spinner
	width: 48px
	border: 5px solid #6DB496
	border-top-color: transparent
	border-radius: 50%
	aspect-ratio: 1/1
	animation-name: spin
	animation-iteration-count: infinite
	animation-timing-function: linear
	animation-duration: 1s

@keyframes spin
	from
		transform: rotate(0deg)
	to
		transform: rotate(360deg)
