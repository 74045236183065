.filterGroup
  position: relative
  min-height: 56px
  padding: 6px 10px
  border: 1px solid rgba(0, 0, 0, 0.3)
  border-radius: 4px
  box-sizing: border-box

  .filterGroupLabel
    position: absolute
    top: 0
    left: 0
    line-height: 1.4375em
    color: rgba(0, 0, 0, 0.6)
    background-color: white
    padding: 5px
    transform-origin: top left
    transform: translate(10px, -14px) scale(0.75)

.showBy
  height: 34px
  padding: 4px 0

.order
  order: 1

.textOverflow
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
